.input-title {
  width: 100%;
  height: 1rem;
  font-size: 0.7rem;
  border: none;
}
.input-title:focus {
  background: #ddd;
}
.editable-title-container {
  position: relative;
  display: flex;
}
.editable-title-container .editable-title {
  height: 1rem;
  flex-grow: 1;
  font-size: 0.5rem;
  /* font-weight: bold; */
}
.editable-title-container .list-button {
  height: 1.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}
.editable-title-container .list-button:hover {
  background-color: #ddd;
}
.editable-title-container .menu-card {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 999;
  list-style-type: none;
  cursor: default;
}
.editable-title-container .menu-card li {
  color: #333;
  font-size: 0.875rem;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0.5rem 0.3rem;
}
.editable-title-container .menu-card li:hover {
  background-color: #ddd;
}
