.input-container {
  width: 100%;
  max-width: 300px;
  margin-right: 0.5rem;
}
.input-container .input-content {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 0.5rem 0;
  opacity: 0.8;
}
.input-container .input-content button {
  cursor: pointer;
  background: none;
  width: 100%;
  padding: 0.5rem;
  border: none;
  margin: auto;
  text-align: left;
  font-size: 14px;
}
.input-container .input-content:hover {
  background-color: #ddd;
}
