.list-cards {
  width: 300px;
  background-color: #f2f2f2;
  margin-right: 0.5rem;
  border-radius: 10px;
}
.list-cards .title-list {
  padding: 0.7rem 0.7rem;
}
.list-cards .container-cards {
  overflow-x: auto;
  max-height: 55vh;
  padding: 0 0.7rem;
}
.list-cards .card-container {
  overflow-y: hidden;
  margin: 0.5rem 0;
}
