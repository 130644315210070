.input-card {
  background-color: #ebecf0;
  padding: 1rem;
  border-radius: 10px;
}
.input-card .input-card-container {
  width: 100%;
  margin: auto;
}
.input-card .input-card-container .input-text {
  width: 100%;
  height: 4rem;
  background-color: #fff;
  padding: 0.5rem;
  font-size: 15px;
  border: none;
  border-bottom: 1px solid #ccc;
  resize: none;
}
.input-card .confirm {
  width: 268px;
  margin: 0.2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-card .confirm .button-confirm {
  height: 2rem;
  width: 6rem;
  background-color: #525252;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  margin-right: 0.5rem;
  text-transform: capitalize;
}
.input-card .confirm .button-confirm:hover {
  background-color: #e22bba;
}
.input-card .confirm .button-cancel {
  background-color: none;
  border: none;
  cursor: pointer;
}
.input-card .confirm .button-cancel svg {
  transition: color 0.3s;
}
.input-card .confirm .button-cancel svg:hover {
  color: #e22bba;
}
