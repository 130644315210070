.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}

input[type="file"] {
  display: none;
}

/* .ribbon3 {
  z-index: 100;
  width: 65px;
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  position: absolute;
  left: -8px;
  top: 10px;
  background: #753188;
  font-size: 11px;
  border-top-left-radius: 5px;
} */
.ribbon3 {
  width: 30px;
  padding: 10px 0 5px 0;
  position: absolute;
  top: -6px;
  left: 55px;
  text-align: center;
  border-top-left-radius: 5px;
  background: #753188;
  font-size: 11px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  z-index: 5;
}
.ribbon3:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: -6px;
  top: 0.1px;
  border-bottom: 6px solid #562f61;
  border-right: 6px solid transparent;
}
.ribbon3:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  bottom: -29.5px;
  left: 0;
}
.ribbon2 {
  width: 30px;
  padding: 10px 0 5px 0;
  position: absolute;
  top: -6px;
  left: 55px;
  text-align: center;
  border-top-left-radius: 5px;
  background: #83829c;
  font-size: 11px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  z-index: 5;
}
.ribbon2:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: -6px;
  top: 0.1px;
  border-bottom: 6px solid #282560;
  border-right: 6px solid transparent;
}
.ribbon2:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  bottom: -29.5px;
  left: 0;
}
